<template>
  <main class="home">
    <section class="progress">
      <p class="stage">4</p>
      <div class="bar"><span></span></div>
      <p class="max">4</p>
    </section>

    <section class="header">
      <h1>Contact details</h1>
      <p>Tell players how they can get in touch (optional)</p>
    </section>
    <div
      class="wrapper"
      v-for="(event, eventIndex) in tour.events"
      :key="eventIndex"
    >
      <section class="tournament-director">
        <h4>Tournament Director</h4>
        <DefaultTextInput
          :value="event.tournamentDirectorName"
          @change="
            $store.dispatch('updateEventStringValue', {
              value: $event,
              property: 'tournamentDirectorName',
              eventIndex: 0,
            })
          "
          :maxlength="50"
          placeholder="Add TD name"
          title="Tournament Director name"
          :fat="true"
        />
        <DefaultTextInput
          :value="event.email"
          @change="
            $store.dispatch('updateEventStringValue', {
              value: $event,
              property: 'email',
              eventIndex: 0,
            })
          "
          :maxlength="50"
          placeholder="Add TD email"
          title="Tournament Director email"
          :fat="true"
        />
        <DefaultTextInput
          :value="event.phone"
          @change="
            $store.dispatch('updateEventStringValue', {
              value: $event,
              property: 'phone',
              eventIndex: 0,
            })
          "
          :maxlength="50"
          placeholder="Add TD phone"
          title="Tournament Director phone"
          :fat="true"
        />
      </section>
      <section class="assistant-tournament-director">
        <h4>Assistant Tournament Director</h4>

        <DefaultTextInput
          :value="event.assistantTournamentDirectorName"
          @change="
            $store.dispatch('updateEventStringValue', {
              value: $event,
              property: 'assistantTournamentDirectorName',
              eventIndex: 0,
            })
          "
          :maxlength="50"
          placeholder="Add Assistant TD name"
          title="Assistant Tournament Director name"
          :fat="true"
        />
        <DefaultTextInput
          :value="event.assistantTournamentDirectorEmail"
          @input="
            $store.dispatch('updateEventStringValue', {
              value: $event,
              property: 'assistantTournamentDirectorEmail',
              eventIndex: 0,
            })
          "
          :maxlength="50"
          placeholder="Add Assistant TD email"
          title="Assistant Tournament Director email"
          :fat="true"
        />
        <DefaultTextInput
          :value="event.assistantTournamentDirectorPhone"
          @input="
            $store.dispatch('updateEventStringValue', {
              value: $event,
              property: 'assistantTournamentDirectorPhone',
              eventIndex: 0,
            })
          "
          :maxlength="50"
          placeholder="Add Assistant TD phone"
          title="Assistant Tournament Director phone"
          :fat="true"
        />
      </section>
      <section class="general">
        <h4>General details</h4>
        <DefaultTextInput
          :value="event.website"
          @change="
            $store.dispatch('updateEventStringValue', {
              value: $event,
              property: 'website',
              eventIndex: 0,
            })
          "
          :maxlength="50"
          placeholder="Add event website"
          title="Event website"
          :fat="true"
        />
        <DefaultTextInput
          :value="event.pdgaEventId"
          @change="
            $store.dispatch('updateEventStringValue', {
              value: $event,
              property: 'pdgaEventId',
              eventIndex: 0,
            })
          "
          :maxlength="50"
          placeholder="Paste PDGA event ID"
          title="PDGA event ID"
          :fat="true"
        />
        <DefaultTextInput
          :value="event.location"
          @change="
            $store.dispatch('updateEventStringValue', {
              value: $event,
              property: 'location',
              eventIndex: 0,
            })
          "
          :maxlength="50"
          placeholder="Add event address"
          title="Event address"
          :fat="true"
        />
        <h6>Additional details</h6>
        <textarea
          maxlength="1500"
          v-model.lazy="event.description"
          placeholder="Add additional details"
        />
      </section>
    </div>

    <section class="nav-buttons">
      <div class="tip">
        <span>
          <Tip />
        </span>
        <p>
          Everything can be edited in the Manager after your event has been
          saved.
        </p>
      </div>
      <StandardBorderedButton
        title="Back"
        :fluid="false"
        :desktopFluidity="true"
        :backwardsIcon="true"
        @click="$router.go(-1)"
      />
      <StandardSolidButton
        class="forwards"
        title="Save league"
        :fluid="false"
        :desktopFluidity="true"
        @click="saveLeague()"
      />
    </section>
    <section class="start-over" @click="$refs.resetmodal.open()">
      Reset and start over
    </section>
    <sweet-modal
      :enable-mobile-fullscreen="false"
      blocking
      ref="resetmodal"
      hide-close-button
    >
      <h3>Reset</h3>
      <p>
        This will remove any information you have added and take you back to the
        start.
      </p>

      <div class="buttons">
        <StandardSolidButton
          title="Cancel"
          :fluid="false"
          @click="$refs.resetmodal.close()"
        />
        <StandardBorderedButton
          class="forwards"
          title="Reset"
          :fluid="false"
          @click="resetAndClose('resetmodal')"
        />
      </div>
    </sweet-modal>
    <sweet-modal
      :enable-mobile-fullscreen="false"
      blocking
      ref="savemodal"
      hide-close-button
    >
      <h3>That’s it!</h3>
      <p>Your league has been saved and is now in the Manager.</p>
      <div class="buttons">
        <StandardBorderedButton
          title="Build more"
          :fluid="false"
          @click="resetAndClose('resetmodal')"
        />
        <StandardSolidButton
          title="Manager"
          :fluid="false"
          @click="toTheManager()"
        />
      </div>
    </sweet-modal>
    <sweet-modal
      :enable-mobile-fullscreen="false"
      blocking
      ref="saveerrormodal"
      hide-close-button
    >
      <h3>Ohh no....</h3>
      <p>
        Something went wrong...
        <br />
        <br />
        We have gathered some information that can help troubleshoot the issue.
        You will be prompted to download a log file when closing this message
        which you can send to our support.
      </p>

      <StandardSolidButton
        class="mt-5"
        title="Close"
        :fluid="false"
        @click="downloadlog()"
      />
    </sweet-modal>
  </main>
</template>

<script>
import moment from "moment";
import DefaultTextInput from "@/components/UIElements/DefaultTextInput";
import StandardBorderedButton from "@/components/UIElements/StandardBorderedButton";
import StandardSolidButton from "@/components/UIElements/StandardSolidButton";
import Tip from "@/assets/icons/Tip";

export default {
  name: "LeagueOptional",
  data() {
    return {
      leagueSaved: false,
      createdLeagueData: null,
    };
  },
  components: {
    DefaultTextInput,
    StandardBorderedButton,
    StandardSolidButton,
    Tip,
  },
  computed: {
    tour() {
      return this.$store.getters.tour.tour;
    },
    league() {
      return this.$store.getters.leagueSettings;
    },
  },
  methods: {
    downloadlog() {
      this.download(this.league.name, JSON.stringify(this.createdLeagueData));
      this.$refs.saveerrormodal.close();
    },
    toTheManager() {
      this.$refs.savemodal.close();
      this.$nextTick(() => {
        this.$router.push({ name: "manage" });
      });
    },
    resetAndClose(modalname) {
      this.$refs[modalname].close();

      this.$store.dispatch("resetBuilderState");
      this.$router.push({ name: "builder" });
    },
    async saveLeague() {
      let cleanedDivisions = [];
      let cleanedEvents = [];
      let baseEvent = JSON.parse(JSON.stringify(this.tour.events[0]));
      let league = this.league;

      this.tour.divisions.forEach((division) => {
        let cleanedDivision = {
          type: division.type,
          currencyCode: division.currency,
        };

        if (division.reservedSpots != null && division.reservedSpots > 0) {
          cleanedDivision.reservedSpots = division.reservedSpots;
        }
        if (division.maxSpots != null && division.maxSpots > 0) {
          cleanedDivision.maxSpots = division.maxSpots;
        }
        if (
          division.registrationFee.value != null &&
          division.registrationFee.value != ""
        ) {
          cleanedDivision.registrationFee = division.registrationFee.value;
        } else {
          cleanedDivision.registrationFee = 0;
        }
        if (
          division.tourPassFee != null &&
          this.tour.connectedAccountId != null
        ) {
          if (division.tourPassFee.value != "") {
            cleanedDivision.tourPassFee = division.tourPassFee.value;
          }
        }

        cleanedDivisions.push(cleanedDivision);
      });

      for (let week = 0; week < league.numberOfWeeks; week++) {
        league.startDates.forEach((eventData) => {
          let clonedEvent = JSON.parse(JSON.stringify(eventData));
          let event = {
            name: league.name,
            description: baseEvent.description,
            maxSpots: baseEvent.maxSpots,
            maxWildcards: baseEvent.maxWildcards,
            tournamentDirectorName: baseEvent.tournamentDirectorName,
            assistantTournamentDirectorName:
              baseEvent.assistantTournamentDirectorName,
            assistantTournamentDirectorEmail:
              baseEvent.assistantTournamentDirectorEmail,
            assistantTournamentDirectorPhone:
              baseEvent.assistantTournamentDirectorPhone,
            phone: baseEvent.phone,
            email: baseEvent.email,
            paymentInfo: baseEvent.paymentInfo,
            location: baseEvent.location,
            website: baseEvent.website,
            isVerifyMode: league.isVerifyMode,
            groupSize: baseEvent.groupSize,
            isResultSigningEnabled: league.isResultSigningEnabled,
            autoOpenRegistration: null,
            cityId: league.city.id,
            pdgaType: baseEvent.pdgaType,
            rounds: [],
            waitinglistType: baseEvent.waitinglistType,
          };

          let eventStartDateTime = moment(clonedEvent.date).add(10, "years");

          clonedEvent.rounds.forEach((round) => {
            round.pools.forEach((pool) => {
              let poolTime = moment(pool.time);
              let poolDate = moment(clonedEvent.date).add(week, "weeks");

              poolDate.hour(poolTime.hour());
              poolDate.minutes(poolTime.minute());
              pool.date = poolDate.toISOString();

              if (poolDate.isBefore(eventStartDateTime)) {
                eventStartDateTime = poolDate;
              }
              pool.layoutVersionId = pool.layout.latestVersion.id;
              delete pool.layout;
              delete pool.time;
            });
            event.rounds.push(round);
          });

          if (league.checkInEnabled) {
            event.checkInStartsAt = moment(eventStartDateTime)
              .subtract(league.checkInOpensMinutesBefore, "minutes")
              .toISOString();
            event.checkInEndsAt = moment(eventStartDateTime)
              .subtract(league.checkInClosesMinutesBefore, "minutes")
              .toISOString();
          }

          if (league.openRegistrationDatesUsed) {
            event.autoOpenRegistration = league.autoOpenRegistration;

            let registrationOpens = moment(eventStartDateTime);
            registrationOpens.subtract(
              clonedEvent.registrationOpenDays,
              "days"
            );
            registrationOpens.subtract(
              clonedEvent.registrationOpenHours,
              "hours"
            );

            event.registrationOpenAt = registrationOpens.toISOString();
          }
          if (
            eventStartDateTime.isAfter(league.startDate) ||
            eventStartDateTime.isSame(league.startDate, "day")
          ) {
            cleanedEvents.push(event);
          }
        });
      }
      let CreateTourExtended = {
        name: league.name,
        scoringType: this.tour.scoringType,
        allowRegistrationMessage: this.tour.allowRegistrationMessage,
        registrationMethod: this.tour.registrationMethod,
        type: "WEEKLY_LEAGUE",
        events: cleanedEvents,
        divisions: cleanedDivisions,
      };

      if (
        this.tour.scoreBasedOnNrOfEvents &&
        this.tour.scoreBasedOnNrOfEvents > 1
      ) {
        CreateTourExtended.scoreBasedOnNrOfEvents =
          this.tour.scoreBasedOnNrOfEvents;
      }

      if (this.tour.connectedAccountId != null) {
        CreateTourExtended.connectedAccountId = this.tour.connectedAccountId;
        CreateTourExtended.paymentType = "MANAGED";
      }

      try {
        let createdTour = await this.$axios({
          headers: { Authorization: this.$store.state.user.sessionInfo.token },
          data: {
            query: `
          mutation CreateTourExtended($CreateTourExtended: ExtendedTourInput!){
            CreateTourExtended(input:$CreateTourExtended){
              id
            }
          }
          `,
            variables: {
              CreateTourExtended,
            },
          },
        });

        this.createdLeagueData = createdTour.data;
        if (createdTour.data.errors) {
          this.$refs.saveerrormodal.open();
          CreateTourExtended.error = createdTour.data;
        } else {
          this.$refs.savemodal.open();
          this.leagueSaved = true;
        }
      } catch (err) {
        this.$refs.saveerrormodal.open();
      }
    },
    download(filename, text) {
      var element = document.createElement("a");
      element.setAttribute(
        "href",
        "data:text/plain;charset=utf-8," + encodeURIComponent(text)
      );
      element.setAttribute("download", filename);

      element.style.display = "none";
      document.body.appendChild(element);

      element.click();

      document.body.removeChild(element);
    },
  },
  mounted() {},
  beforeCreate() {},
  beforeDestroy() {
    if (this.leagueSaved) {
      this.$store.dispatch("resetBuilderState");
    }
  },
};
</script>

<style lang="scss" scoped>
.start-over {
  margin-top: 64px;
  color: $strom;
  font-size: 14px;
  text-align: center;
  cursor: pointer;
  color: $dusk;
}
.nav-buttons {
  margin-top: 22px;
  margin-right: 15px;
  margin-left: 15px;

  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  .tip {
    padding: 15px;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    background: $sunrisemist;
    border: 1px solid #ffd97f;
    border-radius: 6px;
    margin-bottom: 24px;
    width: 100%;
    span {
      margin-right: 10px;
    }

    svg {
      height: 18px;
      width: 14px;
    }

    p {
      display: inline;
      margin: 0;
      font-size: 14px;
    }
  }
  button {
    width: 47%;
  }
}

.wrapper {
  padding: 0 15px;
  margin-top: 64px;
}
.assistant-tournament-director {
  margin-top: 24px;
}
.general {
  background-color: white;
  padding: 32px 15px;
  border-radius: 6px;
  border: 1px solid $sleet;
  margin-top: 24px;

  .payment-text {
    margin-top: 10px;
  }
  h4 {
    font-size: 18px;
    margin-bottom: 0px;
  }

  .default-inputfield {
    margin-top: 24px;
  }

  h6 {
    font-size: 16px;
    margin-top: 24px;
    margin-bottom: 0;
    line-height: 1.8em;
  }

  textarea {
    resize: vertical;
    width: 100%;
    height: 250px;
    min-height: 250px;
    border: 1px solid $blizzard;
    transition: all ease 0.3s;
    border-radius: 6px;
    padding: 15px;
    margin-top: 10px;

    &::placeholder {
      color: $blizzard;
      @include Gilroy-Medium;
    }

    &:hover {
      border: 1px solid $midnight;
    }
  }
}
.assistant-tournament-director,
.tournament-director {
  background-color: white;
  padding: 32px 15px;
  border-radius: 6px;
  border: 1px solid $sleet;

  h4 {
    font-size: 18px;
    margin-bottom: 0px;
  }

  .default-inputfield {
    margin-top: 24px;
  }
}
.progress {
  margin-top: 32px;
  background: none;
  padding: 0 10%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  p {
    margin: 0;
  }

  .stage {
    font-size: 16px;
    @include Gilroy-Bold;
    margin-right: 12px;
  }
  .max {
    font-size: 16px;
    @include Gilroy-Bold;
    margin-left: 12px;
  }
  .bar {
    width: 100%;
    background: $fog;
    height: 8px;
    border-radius: 4px;

    span {
      width: 100%;
      background: $ocean;
      display: flex;
      height: 8px;
      border-radius: 4px;
    }
  }
}
.header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  h1 {
    font-size: 32px;
    @include Gilroy-Bold;
    text-align: center;
    margin-bottom: 16px;
  }
  p {
    font-size: 16px;
    text-align: center;
  }
}

@media (min-width: 480px) and (orientation: landscape) {
}

@media (min-width: 544px) {
}

@media (min-width: 768px) {
  .progress {
    justify-content: center;
    .bar {
      width: 50%;
    }
  }
}

@media (min-width: 1200px) {
  .start-over {
    font-size: 16px;
  }
  .assistant-tournament-director,
  .general {
    margin-top: 32px;
  }
  .nav-buttons {
    margin-top: 64px;

    .tip {
      width: auto;
      order: 2;
      padding: 0 15px;
      display: flex;
      align-items: center;
      margin: 0;
      p {
        font-size: 16px;
      }
      svg {
        margin-right: 8px;
        margin-bottom: 4px;
      }
    }
    .backward {
      order: 1;
      width: auto;
    }
    .forwards {
      order: 3;
    }
  }
  .assistant-tournament-director,
  .tournament-director,
  .general {
    padding: 40px 48px 52px 48px;
    border-radius: 10px;

    h4 {
      font-size: 20px;
    }
    h6 {
      font-size: 16px;
      margin-top: 32px;
      margin-bottom: 0;
    }

    .default-inputfield {
      margin-top: 32px;
      width: 100%;
    }
  }
  .progress {
    margin: auto;
    margin-top: 92px;
    width: 300px;
    padding: 0;

    .bar {
      width: 100%;
    }
  }
  .header {
    h1 {
      font-size: 64px;
      line-height: 64px;
      margin-top: 58px;
      margin-bottom: 0;
    }
    p {
      font-size: 18px;
      margin-top: 24px;
    }
  }
}
</style>

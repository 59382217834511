<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="14" height="18" viewBox="0 0 14 18">
    <path
      class="a"
      d="M525,2979h0a6.86,6.86,0,0,0-7,7c0,4.115,3.846,5.353,4,7h6c.154-1.647,4-2.885,4-7A6.86,6.86,0,0,0,525,2979Z"
      transform="translate(-518 -2979.001)"
    />
    <path class="a" d="M530.917,3008a3,3,0,0,1-6,0" transform="translate(-520.917 -2993.001)" />
  </svg>
</template>

<script>
export default {
  name: "Tip"
};
</script>

<style lang="scss" scoped>
.a {
  fill: #ffb400;
}
</style>